<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-05 16:02:45
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/MediaReport/MediaSearch/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-report-search">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
    </a-range-picker>
    <m-select-more
      class="search_item"
      v-model="searchquery.publisherIdList"
      :allData="supplierList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="供应商"
      @change="changePublisher"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
      :hasSearch="false"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.osList"
      :allData="osList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      :hasSearch="false"
      label="操作系统"
      :showSelectedPart="false"
      width="220px"
      :canSelectAll="false"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDuration } from '@/utils/duration'
export default {
  name: 'MediaReportSearch',
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateStart: undefined,
        dateEnd: undefined,
        publisherIdList: [],
        placeIdList: [],
        positionIdList: [],
        osList: []
      },
      osList: [
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ]
    }
  },
  created () {
    this.initTime()
    this.getSupplierList()
    // this.getAppListForManage()
    this.getPlaceList([])
    this.getPositionList()
    this.handleAutoSearch()
  },
  methods: {
    async changePublisher (ids) {
      // if (ids.length === 0) {
      //   // app无值 通过全部appList查place列表
      //   await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      // } else {
      //   // app选了 通过选了的app查place列表
      //   await this.getPlaceListByAppIds(ids)
      // }
      await this.getPlaceList(ids)
      this.handleAutoSearch()
    },
    // 初始化时间
    initTime () {
      const { endDate, startDate, end, start } = this.initDate(7, 1)
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
    },
    // 时间修改回调
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    handleAutoSearch () {
      this.$emit('handleSearch', this.searchquery)
    }
  }
}
</script>
<style lang="less" scoped>
.media-report-search{
  display: flex;
  justify-content: flex-start;
  margin-bottom: @compMargin;
  .search_item{
    width: 220px;
    margin-bottom: 10px;
    margin-right: @compMargin;
  }
}
</style>
